import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, CardDeck } from "react-bootstrap"
import SignUpComponent from "../components/sign-up"
import MemberCard from "../components/member-card"
import SponsorCard from "../components/sponsor-card"
import { Link, graphql } from "gatsby"

const WhoWeArePage = ({ data }) => {

  const pageData = data.allContentfulAboutPage.nodes[0];
  const steeringCommittee = pageData.steeringCommittee;
  const volunteers = pageData.volunteers;
  const founders = pageData.founders;

  return (
    <Layout>
      <SEO title="Who is POCAM?" />
      <section className="bg-dark">
        <Container className="py-4 py-lg-5">
          <Row className="mb-5">
            <Col md="7" className="text-white">
              <h1 className="display-4 mb-3">{pageData.pageTitle ? pageData.pageTitle : `Who is POCAM?` }</h1>
              <p className="lead">
              People Of Colour in Advertising And Marketing was founded as an industry organization in 2020 by Alyssa Dominique, Stephanie Small, Joshua Richards, Julian Franklin, Justin Senior and Gavin Barrett. It was incorporated as a non-profit in early 2021. The organization has its online roots in the POCA LinkedIn group launched by Stephanie Small in 2019.
              </p>
              <p className="lead">
                We are currrently over <strong>1,770+ members</strong> and
                growing.
              </p>
              <p>
                A steering committee leads POCAM’s mission to empower BIPOC
                advertising and marketing professionals so they can succeed in a
                systemically white working environment. Initiatives include the{" "}
                <Link to="/call-for-equity">Call for Equity</Link> and the{" "}
                <Link to="/visible-and-vocal">Visible & Vocal</Link> study.
              </p>
            </Col>
          </Row>

          {steeringCommittee && pageData.steeringCommitteeTitle && (
            <Row>
              <Col md="8" className="text-white">
                  <h2 className="mb-4">{pageData.steeringCommitteeTitle}</h2>
              </Col>
            </Row>
          )}

          {steeringCommittee && (
            <Row className="row-deck">
              <Col className="pb-5">
                <CardDeck className="cd-10 cd-xl-5">
                  {steeringCommittee.map((member, index) => {
                    return (
                      <MemberCard key={index} index={index} member={member} />
                    )
                    return null
                  })}
                </CardDeck>
              </Col>
            </Row>
          )}

          {founders && pageData.foundersTitle && (
            <Row>
              <Col md="8" className="text-white">
                <h2 className="mb-4">{pageData.foundersTitle}</h2>
              </Col>
            </Row>
          )}

          {founders && (
            <Row className="row-deck">
              <Col className="pb-5">
                <CardDeck className="cd-6 cd-xl-6">
                  {founders.map((member, index) => {
                    return (
                      <MemberCard
                        key={index}
                        index={index}
                        member={member}
                        showBio={false}
                      />
                    )
                  })}
                </CardDeck>
              </Col>
            </Row>
          )}

          {pageData.previousSteeringCommittee && pageData.previousSteeringCommitteeTitle && (
            <Row>
              <Col md="8" className="text-white">
                <h2 className="mb-4">{pageData.previousSteeringCommitteeTitle}</h2>
              </Col>
            </Row>
          )}

          {pageData.previousSteeringCommittee && (
            <Row className="row-deck">
              <Col className="pb-5">
                <CardDeck className="cd-6 cd-xl-6">
                  {pageData.previousSteeringCommittee.map((member, index) => {
                    return (
                      <MemberCard
                        key={index}
                        index={index}
                        member={member}
                        showBio={false}
                      />
                    )
                  })}
                </CardDeck>
              </Col>
            </Row>
          )}
        </Container>
      </section>

      <section>
        <Container className="py-4 py-lg-5">
          {volunteers && pageData.volunteersTitle && (
            <Row>
              <Col md="8">
                <h2 className="mb-4">{pageData.volunteersTitle}</h2>
              </Col>
            </Row>
          )}
          
          {volunteers && (
            <Row className="row-deck">
              <Col className="pb-5">
                <CardDeck className="cd-xl-6">
                  {volunteers.map((member, index) => {
                    return (
                      <MemberCard
                        key={index}
                        index={index}
                        member={member}
                        showBio={false}
                      />
                    )
                  })}
                </CardDeck>
              </Col>
            </Row>
          )}

          {pageData.sponsors && pageData.sponsorsTitle && (
            <Row>
              <Col md="8">
                <h2 className="mb-4">{pageData.sponsorsTitle}</h2>
              </Col>
            </Row>
          )}
          
          {pageData.sponsors && (
            <Row className="row-deck">
              <Col className="pb-5">
                <CardDeck className="cd-xl-6">
                  {pageData.sponsors.map((sponsor, index) => {
                    return (
                      <SponsorCard
                        key={index}
                        index={index}
                        sponsor={sponsor}
                      />
                    )
                  })}
                </CardDeck>
              </Col>
            </Row>
          )}
        </Container>
      </section>

      <SignUpComponent />
    </Layout>
  )
}

export default WhoWeArePage

export const query = graphql`
  query {
    allContentfulAboutPage {
      nodes {
        pageTitle
        steeringCommitteeTitle
        foundersTitle
        previousSteeringCommitteeTitle
        volunteersTitle
        sponsorsTitle
        steeringCommittee {
          name
          linkedinURL
          twitterId
          photo {
            fluid(maxWidth: 258) {
              ...GatsbyContentfulFluid
            }
          }
          shortBiography {
            shortBiography
          }
        }
        volunteers {
          name
          linkedinURL
          twitterId
          photo {
            fluid(maxWidth: 258) {
              ...GatsbyContentfulFluid
            }
          }
          shortBiography {
            shortBiography
          }
        }
        founders {
          name
          linkedinURL
          twitterId
          photo {
            fluid(maxWidth: 258) {
              ...GatsbyContentfulFluid
            }
          }
          shortBiography {
            shortBiography
          }
        }
        previousSteeringCommittee {
          name
          linkedinURL
          twitterId
          photo {
            fluid(maxWidth: 258) {
              ...GatsbyContentfulFluid
            }
          }
          shortBiography {
            shortBiography
          }
        }
        sponsors {
          name
          url
          logo {
            fluid(maxWidth: 258) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
