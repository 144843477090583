import React from "react"
import { Card } from "react-bootstrap"
import Img from "gatsby-image"

const SponsorCard = ({ index, sponsor }) => {
  return (
    <Card className="mt-5 shadow-1" key={index}>
      <Card.Body>
        {sponsor.logo.fluid ? (
          <Card.Link href={sponsor.url} target="_blank">
            <Img className="mb-3" fluid={sponsor.logo.fluid} />
          </Card.Link>
        ) : (
          <img
            alt={sponsor.name}
            className="img-fluid mb-4-up mb-3"
            src="https://placehold.it/300x300/"
          />
        )}
        <Card.Title className="mb-0">{sponsor.name}</Card.Title>
      </Card.Body>
    </Card>
  )
}

export default SponsorCard