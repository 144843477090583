import React from "react"
import { Card } from "react-bootstrap"
import Img from "gatsby-image"

const MemberCard = ({ index, member, showBio = true }) => {
  return (
    <Card className="mt-5 shadow-1" key={index}>
      <Card.Body>
        {member.photo.fluid ? (
          <Img className="mb-4-up mb-3" fluid={member.photo.fluid} />
        ) : (
          <img
            alt={member.name}
            className="img-fluid mb-4-up mb-3"
            src="https://placehold.it/300x300/"
          />
        )}
        <Card.Title className="mb-0">{member.name}</Card.Title>
        {member.time ? (
          <p className="text-center mb-2">
            <small>{member.time}</small>
          </p>
        ) : null}
        {showBio ? (
          <Card.Text>{member.shortBiography.shortBiography}</Card.Text>
        ) : null}
      </Card.Body>
      {member.linkedinURL || member.twitterId ? (
        <Card.Footer>
          <ul className="list-inline mb-0">
            {member.linkedinURL ? (
              <li>
                <a href={member.linkedinURL} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </li>
            ) : null}
            {member.twitterId ? (
              <li>
                <a
                  href={`https://twitter.com/${member.twitterId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  @{member.twitterId}
                </a>
              </li>
            ) : null}
          </ul>
        </Card.Footer>
      ) : null}
    </Card>
  )
}

export default MemberCard
